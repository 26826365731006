/* You can add global styles to this file, and also import other style files */
@import "variables.scss";

@import "../node_modules/bootstrap/scss/mixins.scss";
@import "../node_modules/bootstrap/scss/bootstrap-grid.scss";
@import "../node_modules/bootstrap/scss/bootstrap-utilities.scss";

@import "./styles/icons.scss";

@import "./styles/main.scss";

@import "./styles/print.scss";