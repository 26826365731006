@mixin content-header($space-between-header) {
  min-height: 48px !important;
  padding: 0 8px;

  display: flex !important;
  align-items: center !important;

  background:#ffffff !important;
  color:#444444 !important;

  h4 {
    font-size: 16px;
  }

  > * {
    margin: 0 $space-between-header 0 0;

    &:last-child {
      margin-right: 0;
    }

    &.count {
      padding: 3px 10px 1px;
      background:#ef5350;
      border:1px solid #ca312e;
      border-radius:12px;

      color:#ffffff;
      font-size: 12px;
      line-height: 14px;
    }
  }
}

@mixin section($space-between-header) {
  display: flex;
  flex-direction: column;

  header {
    @include content-header($space-between-header);
  }

  > *.content {
    flex-grow: 1;
    overflow: auto;
  }

  &.full-height {
    height: 100%;
  }
}
