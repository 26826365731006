@media print {
  body {
    height: unset;

    app-root {
      mat-sidenav-container.mat-sidenav-container {
        height: unset;

        mat-sidenav.mat-sidenav {
          display: none;
        }

        mat-sidenav-content.mat-sidenav-content {
          margin-left: 0px !important;

          div.sidenav-content {
            height: unset;

            app-common-header-control {
              display: none;
            }
          }
        }
      }
    }
  }
}
