.k-i-extra {
  font-family: "MaterialIcon-Extra";

  &-factory:before       { @extend .k-i-extra; content: "\e000"; }
  &-customer:before      { @extend .k-i-extra; content: "\e001"; }
  &-order:before         { @extend .k-i-extra; content: "\e002"; }
  &-tool:before          { @extend .k-i-extra; content: "\e003"; }
  &-gear:before          { @extend .k-i-extra; content: "\e004"; }
  &-home:before          { @extend .k-i-extra; content: "\e005"; }
  &-menu:before          { @extend .k-i-extra; content: "\e006"; }
  &-menu-close:before    { @extend .k-i-extra; content: "\e007"; }
  &-truck:before         { @extend .k-i-extra; content: "\e008"; }
  &-driver:before        { @extend .k-i-extra; content: "\e009"; }
  &-tree-file:before     { @extend .k-i-extra; content: "\e00a"; }
  &-search:before        { @extend .k-i-extra; content: "\e00b"; }
  &-save:before          { @extend .k-i-extra; content: "\e00c"; }
  &-plus:before          { @extend .k-i-extra; content: "\e00d"; }
  &-filter:before        { @extend .k-i-extra; content: "\e00e"; }
  &-dot-vertical:before  { @extend .k-i-extra; content: "\e00f"; }
  &-close:before         { @extend .k-i-extra; content: "\e010"; }
  &-calendar:before      { @extend .k-i-extra; content: "\e011"; }
  &-worker:before        { @extend .k-i-extra; content: "\e012"; }
  &-attachment:before    { @extend .k-i-extra; content: "\e013"; }
  &-mail:before          { @extend .k-i-extra; content: "\e014"; }
  &-telephone:before     { @extend .k-i-extra; content: "\e015"; }
  &-chat:before          { @extend .k-i-extra; content: "\e016"; }
  &-link:before          { @extend .k-i-extra; content: "\e017"; }
  &-unlink:before        { @extend .k-i-extra; content: "\e018"; }
  &-column-hide:before   { @extend .k-i-extra; content: "\e019"; }
  &-column-show:before   { @extend .k-i-extra; content: "\e01a"; }
  &-list-edit:before     { @extend .k-i-extra; content: "\e01b"; }
  &-arrow-right:before   { @extend .k-i-extra; content: "\e01c"; }
  &-arrow-left:before    { @extend .k-i-extra; content: "\e01d"; }
  &-factory-shed:before  { @extend .k-i-extra; content: "\e01e"; }
  &-factory-cabin:before { @extend .k-i-extra; content: "\e01f"; }
  &-onsite-kit:before    { @extend .k-i-extra; content: "\e020"; }
  &-open-new:before      { @extend .k-i-extra; content: "\e021"; }
  &-order-return:before  { @extend .k-i-extra; content: "\e022"; }
}
