@import "./common.scss";

@font-face {
  font-family: MaterialIcon-Extra;
  src: url("../assets/font/material-icon-extra.woff2") format("truetype");
}
@font-face {
  font-family: ProximaNova-Regular;
  src: url("../assets/font/proximanova-regular.otf") format("truetype");
}
@font-face {
  font-family: ProximaNova-Regular-Bold;
  src: url("../assets/font/proximanova-regular-bold.otf") format("truetype");
}

*, *::before, *::after {
  box-sizing: inherit;
}

html, body { height: 100%; }
body { margin: 0; font-family: ProximaNova-Regular; font-size: 14px;}
.material-icons-extra {
  font-family: MaterialIcon-Extra;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}
.npl {
  padding-left: 0 !important;
}
.npr {
  padding-right: 0 !important;
}
.txtarea-row-bootstrap {
  line-height: 22.5px !important;
  resize: none;
}
.row-space {
  margin-top: 7px;
}
.content-container .k-form {
  padding: 0px;
}
.k-form {
  & .k-tooltip-validation {
    color: #f45c42;
  }
}
.input-icon {
  position: relative;
  display: flex;

  kendo-autocomplete {
    font-size: 14px;
    padding-left: 30px;
    width: 100%;
    min-width: 220px;
    border: 1px solid #ebebeb;
    border-radius: 18px;
    margin: 13px 19px 13px 0px;
    outline: none;
    //   padding: 8px;
    box-sizing: border-box;
    transition: 0.3s;
    &::-webkit-input-placeholder {
      /* Edge */
      color: #bababa;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #bababa;
    }
    &::placeholder {
      color: #bababa;
    }
    input {
      font-size: 14px;
    }
  }

  i {
    position: absolute;
    left: 1%;
    top: 11px;
    padding: 8px 8px;
    transition: 0.3s;
  }

  kendo-autocomplete:focus + i {
    color: dodgerBlue;
  }

  .input-icon-bg i {
    background-color: #aaa;
    color: #fff;
    padding: 9px 4px;
    border-radius: 4px 0 0 4px;
  }
}
.k-required {
  color: #f79f9d !important;
}
.k-input {
  font-size: 14px !important;
}
.content-container {
  padding: 22px 24px 0 24px;
}

kendo-taglist {
  .k-chip {
    border-color: rgba(0, 0, 0, 0.08) !important;
    border-radius: 2px;
    color: #444;
    background-color: #fff;
    background-image: linear-gradient(#fff, #fafafa);

    .k-chip-remove-action > .k-icon {
      opacity: 0.6;
      &::before {
        content: '\e11b';
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}

.k-chip {
  border-radius: 12px;

  .k-chip-content {
    line-height: 12px;
  }

  .k-chip-actions .k-chip-remove-action {
    margin: 0 4px;
  }

  &.k-chip-solid-error {
    border-color: #ca312e;
    border-radius: 12px;
    color: #fff;
    background-color: #ef5350;
  }

  &.k-chip-solid-info {
    border-color: rgba(41, 182, 246, 0.3);
    color: #29b6f6;
    background-color: rgba(41, 182, 246, 0.12);
  }
}

.group-items {
  .k-list-item.k-disabled {
    padding: 0;
    min-height: 1px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.08);
  }
  kendo-button-list {
    .k-list-item.k-disabled {
      padding: $list-item-padding-y $list-item-padding-x !important;
      min-height: $line-height-em !important;
      height: auto;
      background-color: transparent !important;
    }
  }
}

kendo-dialog {
  .k-dialog {
    width: 500px;

    .k-dialog-content {
      .k-form {
        padding: 0;
      }
    }
  }
}

.b {
  border: 0 solid $base-border;
  &.b-t {
    border-top-width: 1px;
  }
  &.b-l {
    border-left-width: 1px;
  }
  &.b-r {
    border-right-width: 1px;
  }
  &.b-b {
    border-bottom-width: 1px;
  }
}

.k-grid {
  th,
  td {
      &:first-child {
        text-overflow: clip !important;
      }
  }
  tr.dragging {
    background-color: #f45c42;
  }
}

.status-tag {
  background:#ef5350;
  border-radius:9px;
  color:#ffffff;
  line-height:18px;
  padding: 0 8px;
  font-size: 13px;
  display: inline-block;
  text-transform: capitalize;

  &-active {
    background:#66bb6a;
  }
}

.delivery-planner-timeline {
  .k-scheduler-footer,
  .k-scheduler-pane:last-child {
    display: none;
  }
}

main {
  @include section(8px);
}

section {
  @include section(4px);
}

kendo-panelbar {
  // border: none !important;

  kendo-panelbar-item {
    border: none !important;

    .k-link {
      @include content-header(8px);
      border-bottom: 1px solid $base-border;
      border-top: none;
      .k-icon-wrapper-host .k-panelbar-expand, 
      .k-icon-wrapper-host .k-panelbar-collapse {
        top: auto;
      }
      .up-l {
        margin-right: 22px;
      }
    }

    .k-panelbar-content {
      .k-item {
        border-bottom: 1px solid $base-border;
        padding: 8px;
      }
    }
  }
}

.anchor-name a {
  color: #007bff;
  cursor: pointer;
}

.k-avatar-icon, .k-avatar-initials {
  color: #fff;
  background-color: #ff6358;
}

.k-notification-group {
  z-index: 10000;
}
.notes-permit, .project-planner {
  .k-panelbar > .k-item > .k-link .k-icon {
    color: inherit;
  }
}
.order-list {
  kendo-panelbar kendo-panelbar-item {
    .content {
      padding: 8px 12px;
    }
  }
  .k-panelbar > .k-item > .k-link .k-icon {
    color: inherit;
  }
  .avatar-title {
    padding: 6px 12px;
  }

  .k-grid {
    .avatar-col {
      padding: 3px !important;
      .avatar-title {
        max-width: calc(100% - 42px);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .custom-drawer {
    .k-drawer-item {
      padding: 0 !important;
      ul.k-tabstrip-items {
        flex-direction: unset !important;
      }
    }
    .k-drawer-item:hover {
      background: none !important;
    }
    .k-drawer-item.k-state-selected {
      background: none !important;
    }
  }

  .k-grid-edit-row {
    .k-dropdownlist.dropdown-radius {
      border-radius: 18px !important;
      height: 30px;
      .k-input {
        padding-left: 0 !important;
      }
      .k-input-inner {
        padding: 4px 0;
        .k-input-value-text::before {
          content: none;
        }
      }
    }
    .k-dropdownlist {
      border-radius: 2px !important;
    }
  }
  .email-content p {
    margin: 0 !important;
  }
}

.k-grid-pager {
  .k-numerictextbox {
    height: 30px;
    width: 100px;
    .k-input {
      height: 30px;
    }
  }
}

.k-scheduler-table.k-scheduler-header-all-day,
.k-scheduler-header-wrap .k-event {
  height: auto !important;
}

.kendo-dialog-titlebar div {
  font-size: 18px;
  line-height: 1.3em;
}

.kendo-dialog p {
  margin: 30px;
  text-align: center;
}

span.label-align-center {
  display: inline-block;
  vertical-align: sub;
}

kendo-grid-filter-cell-operators {
  display: flex;
}

.save-btn {
  background: #129bf3;
  border: 1px solid #13a0f3;
  border-radius: 2px;
  width: 76px;
  height: 30px;
  margin-right: 4px;
  i.material-icons {
    font-size: 16px;
    margin-right: 4px;
    vertical-align: middle;
  }
}

.cancel-btn {
  background: #fefefe;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  width: 67px;
  height: 30px;
}

.k-grid-header .k-header {
  text-transform: capitalize;
}

.hide-dropzone {
  & .k-dropzone {
    display: none;
  }
}

kendo-card-header {
  kendo-avatar {
    display: none !important;
  }
}

.k-grid-table {
  & kendo-avatar {
    display: none;
  }
}

.k-panelbar > .k-item > .k-link,
.k-panelbar > .k-panelbar-header > .k-link {
  padding: 0 8px;

  // > * {
  //   margin: 0 4px 0 0;
  // }

  > .k-icon-wrapper-host:last-child {
    position: absolute;
    right: 8px;
    &.k-panelbar-expand::before { content: "\e006"; }
    &.k-panelbar-collapse::before { content: "\e004"; }
  }
}

.k-textbox:disabled,
.k-textbox[disabled],
.k-textbox.k-state-disabled,
.k-textarea:disabled,
.k-textarea[disabled],
.k-textarea.k-state-disabled {
  outline: none;
  cursor: default;
  opacity: .6;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}

.k-dialog-actions {
  justify-content: flex-end;
  .k-button {
    flex: none;
    ~ .k-button {
      margin-left: 8px;
    }
  }
}

.k-dropdownlist {
  background: #fff;
  background-image: linear-gradient(#fff,#fafafa);
  display: flex;
  &:hover {
    border-color: rgba(0,0,0,0.15);
    color: #444;
    background-color: #cde6f7;
    background-image: linear-gradient(#cde6f7, #c4e2f6);
  }
}

.k-dropdownlist:focus, .k-dropdownlist.k-focus,
.k-input:focus, .k-input.k-focus {
  box-shadow: none;
}

.k-input .k-input-button,
.k-input .k-spinner-increase,
.k-input .k-spinner-decrease,
.k-picker .k-input-button,
.k-picker .k-spinner-increase,
.k-picker .k-spinner-decrease {
  background: transparent !important;
}

.k-input,
.k-picker,
.k-button {
  border-radius: 2px;
}

.k-list .k-list-item,
.k-list .k-list-optionlabel {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.k-checkbox:indeterminate,
.k-checkbox.k-indeterminate {
  background-image: none;
}


.k-datepicker .k-picker-wrap,
.k-timepicker .k-picker-wrap,
.k-datetimepicker .k-picker-wrap {
  border-color: rgba(0, 0, 0, 0.08);
  color: #444;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  .k-input {
    border: 0;
  }
  .k-select {
    display: flex;
    width: 28px;
    height: 28px;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
  }
}

.k-datepicker .k-picker-wrap:hover,
.k-timepicker .k-picker-wrap:hover,
.k-datetimepicker .k-picker-wrap:hover {
  .k-select {
    cursor: pointer;
    border-color: rgba(0,0,0,0.15);
    color: #444;
    background-color: #cde6f7;
    background-image: linear-gradient(#cde6f7,#c4e2f6);
  }
}

/* this might be a good full class references, keep for future usage */
// .k-dateinput .k-dateinput-wrap .k-select,
// .k-dateinput .k-picker-wrap .k-select,
// .k-datepicker .k-dateinput-wrap .k-select,
// .k-datepicker .k-picker-wrap .k-select,
// .k-datetimepicker .k-dateinput-wrap .k-select,
// .k-datetimepicker .k-picker-wrap .k-select,
// .k-timepicker .k-dateinput-wrap .k-select,
// .k-timepicker .k-picker-wrap .k-select {
// }

.btn-radius,
.k-icon-button {
  width: 30px;
  height: 30px;
}

.k-button:hover, .k-button.k-state-hover {
  border-color: rgba(0,0,0,0.15);
  color: #444;
  background-color: #cde6f7;
  background-image: linear-gradient(#cde6f7,#c4e2f6);
}

.crm-ref-dialog {
  .k-form-inline {
    padding: 0;
    .k-form-field {
      span {
        text-align: left; width: fit-content;
      }
      .k-textbox {
        width: 40px;
      }
    }
  }
}

.text-right-sortcol .k-link {
	justify-content: end;
}

.k-footer-template > td {
  padding: 0;
}
.k-footer-template .footer-cell {
  height: 33px;
  padding: 10px;
  overflow: hidden;
  &.rm-height {
    height: auto;
  }
}
.k-footer-template .footer-cell:last-of-type {
  border-bottom: 0;
}

.k-grid .k-grid-content-sticky, .k-grid-header th.k-grid-header-sticky, .k-grid-footer .k-grid-footer-sticky {
  border-left-color: rgba(0, 0, 0, 0.08);
  border-right-color: rgba(0, 0, 0, 0.08);
}

.k-button-pw {
  margin: -8px;
}

.k-grid tr.hide-row {
  display: none;
}

kendo-editor.k-readonly.readonly-not-opacity {
  opacity: 1 !important;
}

.hide-arrow-dropdown {
  .k-i-arrow-s {
    display: none;
  }
}